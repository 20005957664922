
import style from "./styles/rejectButton.module.css";

export default function RejectButtton(props) {

  //style.butBody

    return(
      <div className={style.butBody}>
        <label>Отклонить всё</label>
      </div>
    )

};