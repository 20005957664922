
import style from './styles/blockApplication.module.css'

export default function Block_application(props) {


  return (

    <>
      <div className={style.containerBody}>
        <table>
          <tbody>
            <tr>
              <td className={style.topLineTitle}>
                <label className={style.projectTitle}>{'Title of project'}</label>
                <label className={style.projectSubTitle}>{'source of project'}</label>
              </td>
              <td></td>
              <td className={style.projectStatus}>
                <label>{'На рассмотрении'}</label>
                <label>{'до дд.мм.гг'}</label>
              </td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td className={style.projectInfoBar}>
                <div>
                  <svg className={style.SVG_ProjectInfoBar} viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                    <rect width="47" height="46" rx="23" fill="black"/>
                    <path d="M34.4997 22.7082H13.4997C12.3797 22.7082 11.458 21.7866 11.458 20.6666V16.7932C11.458 15.9999 12.0063 15.1949 12.7413 14.9032L23.2413 10.7033C23.6846 10.5283 24.3147 10.5283 24.758 10.7033L35.258 14.9032C35.993 15.1949 36.5413 16.0116 36.5413 16.7932V20.6666C36.5413 21.7866 35.6197 22.7082 34.4997 22.7082ZM23.9997 12.3133C23.953 12.3133 23.9064 12.3132 23.883 12.3249L13.3946 16.5249C13.3246 16.5599 13.208 16.7116 13.208 16.7932V20.6666C13.208 20.8299 13.3363 20.9582 13.4997 20.9582H34.4997C34.663 20.9582 34.7913 20.8299 34.7913 20.6666V16.7932C34.7913 16.7116 34.6864 16.5599 34.6047 16.5249L24.1047 12.3249C24.0814 12.3132 24.0463 12.3133 23.9997 12.3133Z" fill="white"/>
                    <path d="M35.6663 35.5417H12.333C11.8547 35.5417 11.458 35.145 11.458 34.6667V31.1667C11.458 30.0467 12.3797 29.125 13.4997 29.125H34.4997C35.6197 29.125 36.5413 30.0467 36.5413 31.1667V34.6667C36.5413 35.145 36.1447 35.5417 35.6663 35.5417ZM13.208 33.7917H34.7913V31.1667C34.7913 31.0033 34.663 30.875 34.4997 30.875H13.4997C13.3363 30.875 13.208 31.0033 13.208 31.1667V33.7917Z" fill="white"/>
                    <path d="M14.667 30.8749C14.1887 30.8749 13.792 30.4783 13.792 29.9999V21.8333C13.792 21.3549 14.1887 20.9583 14.667 20.9583C15.1453 20.9583 15.542 21.3549 15.542 21.8333V29.9999C15.542 30.4783 15.1453 30.8749 14.667 30.8749Z" fill="white"/>
                    <path d="M19.333 30.8749C18.8547 30.8749 18.458 30.4783 18.458 29.9999V21.8333C18.458 21.3549 18.8547 20.9583 19.333 20.9583C19.8113 20.9583 20.208 21.3549 20.208 21.8333V29.9999C20.208 30.4783 19.8113 30.8749 19.333 30.8749Z" fill="white"/>
                    <path d="M24 30.8749C23.5217 30.8749 23.125 30.4783 23.125 29.9999V21.8333C23.125 21.3549 23.5217 20.9583 24 20.9583C24.4783 20.9583 24.875 21.3549 24.875 21.8333V29.9999C24.875 30.4783 24.4783 30.8749 24 30.8749Z" fill="white"/>
                    <path d="M28.667 30.8749C28.1887 30.8749 27.792 30.4783 27.792 29.9999V21.8333C27.792 21.3549 28.1887 20.9583 28.667 20.9583C29.1453 20.9583 29.542 21.3549 29.542 21.8333V29.9999C29.542 30.4783 29.1453 30.8749 28.667 30.8749Z" fill="white"/>
                    <path d="M33.333 30.8749C32.8547 30.8749 32.458 30.4783 32.458 29.9999V21.8333C32.458 21.3549 32.8547 20.9583 33.333 20.9583C33.8113 20.9583 34.208 21.3549 34.208 21.8333V29.9999C34.208 30.4783 33.8113 30.8749 33.333 30.8749Z" fill="white"/>
                    <path d="M36.8337 35.5417H11.167C10.6887 35.5417 10.292 35.1451 10.292 34.6667C10.292 34.1884 10.6887 33.7917 11.167 33.7917H36.8337C37.312 33.7917 37.7087 34.1884 37.7087 34.6667C37.7087 35.1451 37.312 35.5417 36.8337 35.5417Z" fill="white"/>
                    <path d="M24 19.7917C22.5533 19.7917 21.375 18.6134 21.375 17.1667C21.375 15.7201 22.5533 14.5417 24 14.5417C25.4467 14.5417 26.625 15.7201 26.625 17.1667C26.625 18.6134 25.4467 19.7917 24 19.7917ZM24 16.2917C23.5217 16.2917 23.125 16.6884 23.125 17.1667C23.125 17.6451 23.5217 18.0417 24 18.0417C24.4783 18.0417 24.875 17.6451 24.875 17.1667C24.875 16.6884 24.4783 16.2917 24 16.2917Z" fill="white"/>
                    </g>
                  </svg>
                  <label>{'school'}</label> 
                </div>
                <div>
                  <svg className={style.SVG_ProjectInfoBar} viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                    <rect width="47" height="46" rx="23" fill="black"/>
                    <path d="M27.4997 35.5416H20.4997C14.1647 35.5416 11.458 32.8349 11.458 26.4999V19.4999C11.458 13.1649 14.1647 10.4583 20.4997 10.4583H26.333C26.8113 10.4583 27.208 10.8549 27.208 11.3333C27.208 11.8116 26.8113 12.2083 26.333 12.2083H20.4997C15.1213 12.2083 13.208 14.1216 13.208 19.4999V26.4999C13.208 31.8783 15.1213 33.7916 20.4997 33.7916H27.4997C32.878 33.7916 34.7913 31.8783 34.7913 26.4999V20.6666C34.7913 20.1883 35.188 19.7916 35.6663 19.7916C36.1447 19.7916 36.5413 20.1883 36.5413 20.6666V26.4999C36.5413 32.8349 33.8347 35.5416 27.4997 35.5416Z" fill="white"/>
                    <path d="M35.6663 21.5416H30.9997C27.0097 21.5416 25.458 19.9899 25.458 15.9999V11.3332C25.458 10.9832 25.668 10.6566 25.9947 10.5282C26.3213 10.3882 26.6947 10.4699 26.9513 10.7149L36.2847 20.0482C36.5297 20.2932 36.6113 20.6782 36.4713 21.0049C36.3313 21.3316 36.0163 21.5416 35.6663 21.5416ZM27.208 13.4449V15.9999C27.208 19.0099 27.9897 19.7916 30.9997 19.7916H33.5547L27.208 13.4449Z" fill="white"/>
                    </g>
                  </svg>
                  <label>{'prj type'}</label>
                </div>
                <div>
                  <svg className={style.SVG_ProjectInfoBar} viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                    <rect width="47" height="46" rx="23" fill="black"/>
                    <path d="M19.333 15.7083C18.8547 15.7083 18.458 15.3116 18.458 14.8333V11.3333C18.458 10.8549 18.8547 10.4583 19.333 10.4583C19.8113 10.4583 20.208 10.8549 20.208 11.3333V14.8333C20.208 15.3116 19.8113 15.7083 19.333 15.7083Z" fill="white"/>
                    <path d="M28.667 15.7083C28.1887 15.7083 27.792 15.3116 27.792 14.8333V11.3333C27.792 10.8549 28.1887 10.4583 28.667 10.4583C29.1453 10.4583 29.542 10.8549 29.542 11.3333V14.8333C29.542 15.3116 29.1453 15.7083 28.667 15.7083Z" fill="white"/>
                    <path d="M19.9167 25.9167C19.765 25.9167 19.6133 25.8817 19.4733 25.8234C19.3217 25.7651 19.205 25.6834 19.0883 25.5784C18.8783 25.3567 18.75 25.065 18.75 24.75C18.75 24.5984 18.785 24.4467 18.8433 24.3067C18.9017 24.1667 18.9833 24.0384 19.0883 23.9217C19.205 23.8167 19.3217 23.735 19.4733 23.6767C19.8933 23.5017 20.4183 23.595 20.745 23.9217C20.955 24.1434 21.0833 24.4467 21.0833 24.75C21.0833 24.82 21.0717 24.9017 21.06 24.9834C21.0483 25.0534 21.025 25.1234 20.99 25.1934C20.9667 25.2634 20.9317 25.3334 20.885 25.4034C20.85 25.4617 20.7917 25.52 20.745 25.5784C20.5233 25.7884 20.22 25.9167 19.9167 25.9167Z" fill="white"/>
                    <path d="M23.9997 25.9168C23.848 25.9168 23.6963 25.8818 23.5563 25.8234C23.4047 25.7651 23.288 25.6834 23.1713 25.5784C22.9613 25.3568 22.833 25.0651 22.833 24.7501C22.833 24.5984 22.868 24.4468 22.9263 24.3068C22.9847 24.1668 23.0663 24.0384 23.1713 23.9218C23.288 23.8168 23.4047 23.7351 23.5563 23.6767C23.9763 23.4901 24.5013 23.5951 24.828 23.9218C25.038 24.1434 25.1663 24.4468 25.1663 24.7501C25.1663 24.8201 25.1547 24.9018 25.143 24.9834C25.1313 25.0534 25.108 25.1234 25.073 25.1934C25.0497 25.2634 25.0147 25.3334 24.968 25.4034C24.933 25.4618 24.8747 25.5201 24.828 25.5784C24.6063 25.7884 24.303 25.9168 23.9997 25.9168Z" fill="white"/>
                    <path d="M28.0837 25.9168C27.932 25.9168 27.7803 25.8818 27.6403 25.8234C27.4887 25.7651 27.372 25.6834 27.2553 25.5784C27.2087 25.5201 27.162 25.4618 27.1153 25.4034C27.0687 25.3334 27.0337 25.2634 27.0103 25.1934C26.9753 25.1234 26.952 25.0534 26.9403 24.9834C26.9287 24.9018 26.917 24.8201 26.917 24.7501C26.917 24.4468 27.0453 24.1434 27.2553 23.9218C27.372 23.8168 27.4887 23.7351 27.6403 23.6767C28.072 23.4901 28.5853 23.5951 28.912 23.9218C29.122 24.1434 29.2503 24.4468 29.2503 24.7501C29.2503 24.8201 29.2387 24.9018 29.227 24.9834C29.2153 25.0534 29.192 25.1234 29.157 25.1934C29.1337 25.2634 29.0987 25.3334 29.052 25.4034C29.017 25.4618 28.9587 25.5201 28.912 25.5784C28.6903 25.7884 28.387 25.9168 28.0837 25.9168Z" fill="white"/>
                    <path d="M19.9167 29.9999C19.765 29.9999 19.6133 29.9649 19.4733 29.9066C19.3333 29.8482 19.205 29.7665 19.0883 29.6615C18.8783 29.4399 18.75 29.1366 18.75 28.8332C18.75 28.6816 18.785 28.5299 18.8433 28.3899C18.9017 28.2382 18.9833 28.1099 19.0883 28.0049C19.52 27.5732 20.3133 27.5732 20.745 28.0049C20.955 28.2266 21.0833 28.5299 21.0833 28.8332C21.0833 29.1366 20.955 29.4399 20.745 29.6615C20.5233 29.8715 20.22 29.9999 19.9167 29.9999Z" fill="white"/>
                    <path d="M23.9997 29.9999C23.6963 29.9999 23.393 29.8715 23.1713 29.6615C22.9613 29.4399 22.833 29.1366 22.833 28.8332C22.833 28.6816 22.868 28.5299 22.9263 28.3899C22.9847 28.2382 23.0663 28.1099 23.1713 28.0049C23.603 27.5732 24.3963 27.5732 24.828 28.0049C24.933 28.1099 25.0147 28.2382 25.073 28.3899C25.1313 28.5299 25.1663 28.6816 25.1663 28.8332C25.1663 29.1366 25.038 29.4399 24.828 29.6615C24.6063 29.8715 24.303 29.9999 23.9997 29.9999Z" fill="white"/>
                    <path d="M28.0837 30.0001C27.7803 30.0001 27.477 29.8718 27.2553 29.6618C27.1503 29.5568 27.0687 29.4285 27.0103 29.2768C26.952 29.1368 26.917 28.9851 26.917 28.8335C26.917 28.6818 26.952 28.5301 27.0103 28.3901C27.0687 28.2385 27.1503 28.1102 27.2553 28.0052C27.5237 27.7368 27.932 27.6085 28.3053 27.6901C28.387 27.7018 28.457 27.7251 28.527 27.7601C28.597 27.7835 28.667 27.8185 28.737 27.8652C28.7953 27.9002 28.8537 27.9585 28.912 28.0052C29.122 28.2268 29.2503 28.5301 29.2503 28.8335C29.2503 29.1368 29.122 29.4401 28.912 29.6618C28.6903 29.8718 28.387 30.0001 28.0837 30.0001Z" fill="white"/>
                    <path d="M33.9163 20.48H14.083C13.6047 20.48 13.208 20.0833 13.208 19.605C13.208 19.1266 13.6047 18.73 14.083 18.73H33.9163C34.3947 18.73 34.7913 19.1266 34.7913 19.605C34.7913 20.0833 34.3947 20.48 33.9163 20.48Z" fill="white"/>
                    <path d="M28.6667 35.5416H19.3333C15.075 35.5416 12.625 33.0916 12.625 28.8333V18.9166C12.625 14.6583 15.075 12.2083 19.3333 12.2083H28.6667C32.925 12.2083 35.375 14.6583 35.375 18.9166V28.8333C35.375 33.0916 32.925 35.5416 28.6667 35.5416ZM19.3333 13.9583C15.9967 13.9583 14.375 15.5799 14.375 18.9166V28.8333C14.375 32.1699 15.9967 33.7916 19.3333 33.7916H28.6667C32.0033 33.7916 33.625 32.1699 33.625 28.8333V18.9166C33.625 15.5799 32.0033 13.9583 28.6667 13.9583H19.3333Z" fill="white"/>
                    </g>
                  </svg>
                  <label>{'n'} мес.</label>
                </div>
              </td>
              <td></td>
              <td className={style.buttonsBlock}> 
                <svg className={style.buttonSVG} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="36" height="36" rx="8"/>
                  <path d="M6 18L14 26" stroke="white" stroke-width="4" stroke-linecap="round"/>
                  <path d="M30 10L14 26" stroke="white" stroke-width="4" stroke-linecap="round"/>
                </svg>

                <svg className={style.buttonSVG} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="36" height="36" rx="8"/>
                  <path d="M26 10L10 26" stroke="white" stroke-width="4" stroke-linecap="round"/>
                  <path d="M26 26L10 10" stroke="white" stroke-width="4" stroke-linecap="round"/>
                </svg>


              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  
  )
    
  };